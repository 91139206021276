<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="visibleBreadcrumbs" disable-button />
    <div class="row">
      <div class="col-md-10 col-sm-12">
        <v-card class="rounded-card" flat outlined>
          <v-card-text class="mt-5 mb-3 px-12">
            <app-form ref="form" :fields="fields"
              :submitButton="submitButton"
              @submitted="handleSubmit"
              disableCancelButton
            />
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-container>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  name: 'newKudoRequest',
  data() {
    return {
      id: null,
      title: {
        text: this.$t('layout.accountNavMenu.requestNewKudos'),
        icon: 'mdi-plus'
      },
      isEditMode: false,
      submitButton: {
        label: this.$t('actions.submit')
      },
      fields: {
        title: {
          type: 'text',
          key: 'title',
          value: '',
          label: this.$t('models.kudo.attributes.title'),
          rules: [{name: 'required'}],
          classes: 'col-12 py-0',
          serverErrors: []
        },
        description: {
          type: 'text',
          key: 'description',
          value: '',
          label: this.$t('models.kudo.attributes.description'),
          rules: [{name: 'required'}],
          classes: 'col-12 py-0',
          serverErrors: []
        },
        badge: {
          type: 'text',
          key: 'badge',
          value: '',
          label: this.$t('models.kudo.attributes.badge'),
          rules: [{name: 'required'}],
          classes: 'col-12 py-0',
          serverErrors: []
        },
        creator_id: {
          type: 'select',
          key: 'creator_id',
          value: '',
          label: this.$t('models.kudo.creator'),
          rules: [{name: 'requiredSelect'}],
          options: [],
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-12 py-0',
          serverErrors: [],
          disabled: false
        },
        assignee_id: {
          type: 'select',
          key: 'assignee_id',
          value: '',
          placeholder: this.$t('models.kudo.assignee'),
          label: this.$t('models.kudo.assignee'),
          rules: [{name: 'requiredSelect'}],
          options: [],
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-12 py-0',
          serverErrors: []
        }
      }
    };
  },
  computed: {
    ...mapGetters([
      'users',
      'user',
      'isAdmin',
      'currentUser',
      'kudoEditted'
    ]),
    breadcrumbs() {
      return [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'},
        show: true
      },
      {
        text: this.$t('layout.mainMenu.kudos'),
        to: {name: 'Kudos'},
        show: true,
        exact: true
      },
      {
        text: this.$route.params.id,
        show: this.isEditMode,
        disabled: true
      },
      {
        text: this.isEditMode ? this.$t('views.kudos.edit') : this.$t('views.kudos.new'),
        to: this.isEditMode ? {name: 'EditKudoRequest'} : {name: 'NewKudoRequest'},
        exact: true,
        show: true
      }
      ];
    },
    visibleBreadcrumbs() {
      return this.breadcrumbs.filter((breadcrumb) => breadcrumb.show);
    }
  },
  methods: {
    ...mapActions([
      'getUsers',
      'createKudo',
      'getKudoEditted',
      'updateKudo'
    ]),
    handleSubmit(data) {
      if (data.assignee_id === data.creator_id) {
        this.fields.assignee_id.value = '';
        this.fields.assignee_id.placeholder = '';
      } else {
        if (this.isEditMode) {
          const req = {
            data,
            id: this.id
          };
          this.updateKudo(req);
          this.$router.push({
            name: 'Kudos'
          });
        } else {
          const request = this.createKudo(data);
          request.then((response) => {
            if (this.successCode(response.status)) {
              this.$router.push({
                name: 'Kudos'
              });
            } else {
              this.displayInlineServerErrors(this.fields, response.errors);
            }
          });
        }
      }
    }
  },
  watch: {
    '$route.params.id': {
      async handler(id) {
        this.isEditMode = false;
        if (id) {
          this.isEditMode = true;
          this.id = id;
          this.title.text = this.$t('layout.accountNavMenu.editKudoRequest');
          await this.getKudoEditted(this.$route.params.id);
          const kudo = this.kudoEditted.items[0];

          this.fields.title.value = kudo.title;
          this.fields.description.value = kudo.description;
          this.fields.badge.value = kudo.badge;
          this.fields.assignee_id.placeholder = kudo.assignee_name;
        } else {
          this.fields.assignee_id.options = this.users.items
            .filter((user) => user.id !== this.currentUser.id)
            .map((user) => ({key: `${user.id}`, text: user.name}));
          if (this.isAdmin) {
            this.fields.creator_id.options = this._.map(this.users.items, (user) => ({key: `${user.id}`, text: user.name}));
          } else {
            this.fields.creator_id.options = [
              {
                key: this.currentUser.id,
                text: this.currentUser.name
              }
            ];
          }
          this.fields.creator_id.value = this.currentUser.id;
          this.fields.creator_id.placeholder = this.currentUser.name;
          this.fields.title.value = null;
          this.fields.description.value = null;
          this.fields.badge.value = null;
          this.fields.assignee_id.placeholder = '';
        }
      },
      immediate: true
    }
  },
  created() {
    Promise.all([
      this.getUsers()
    ]).then(()=>{
      this.fields.assignee_id.options = this.users.items
        .filter((user) => user.id !== this.currentUser.id)
        .map((user) => ({key: `${user.id}`, text: user.name}));
      if (this.isAdmin) {
        this.fields.creator_id.options = this._.map(this.users.items, (user) => ({key: `${user.id}`, text: user.name}));
      } else {
        this.fields.creator_id.options = [
          {
            key: this.currentUser.id,
            text: this.currentUser.name
          }
        ];
      }
      this.fields.creator_id.value = this.currentUser.id;
      this.fields.creator_id.placeholder = this.currentUser.name;
    });
  }
};
</script>
